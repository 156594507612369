import BaseController from '@/service/BaseService'
import RoleRequest from '@/request/System/RoleRequest'
import MenuRequest from '@/request/System/MenuRequest'
import { successTip } from '@/utils/Tip'

const request = new RoleRequest()
const requestMenu = new MenuRequest()
const base = new BaseController()

export default {
  data () {
    return {
      ruleDialog: false,
      showDialog: false, // 是否显示弹窗
      tableLoad: false, // 表格加载
      scene: 0, // 场景: 0表示弹框为添加，1表示弹框为修改
      roleId: 0,
      ruleForm: {},
      ruleRules: {},
      MenuChecked: [],
      menuTree: [],
      // 表单信息
      form: {
        name: '',
        status: ''
      },
      // 表单验证规则
      rules: {
        name: [{
          required: true,
          message: '请输入角色名称'
        }],
        status: [{
          required: true,
          message: '请输入帐号状态'
        }]
      },

      // 分页信息[前端控制单页数量]
      pageData: {
        page: 1,
        limit: 10,
        total: 0,
        list: [],
        select: {
          status: {}
        }
      },

      // 表单筛选
      searchForm: {
        name: null,
        status: null,
        create_time_array: []
      }
    }
  },
  // 初始化函数
  created: async function () {
    // 初始化父类和列表数据
    await base.init(this, request).getList()
    // 初始化权限树
    await this.getRoleTree()
  },
  methods: {
    // 获取权限树状数据
    getRoleTree: async function () {
      const result = await requestMenu.listPost({ ...this.pageData })
      if (result.code === 200) {
        this.menuTree = result.data
      }
    },
    // 权限弹窗
    ruleClick: async function (row) {
      const result = await request.RoleMenuIds(row)
      if (result.code === 200) {
        this.MenuChecked = result.data
        console.log(this.MenuChecked)

        setTimeout(() => {
          this.ruleDialog = true
          this.roleId = row.id
        }, 1000)
      }
    },

    // 获取当前选中的角色对应的菜单
    RoleMenuIds: async function (row) {
      const result = await request.RoleMenuIds(row)
      if (result.code === 200) {
        this.MenuChecked = result.data
      }
    },

    // 分配权限窗口关闭
    ruleClose: function () {
      this.ruleDialog = false
      this.$refs.subRoleTree.setCheckedKeys([])
      this.MenuChecked = []
    },

    ruleSubmitButton: async function () {
      const menuId = this.$refs.subRoleTree.getCheckedKeys().concat()

      const result = await request.SetRoleMenu({
        role_id: this.roleId,
        menu_id: menuId
      })
      if (result.code !== 200) this.errorTip(result.message)
      // 刷新页面
      await base.getList()
      successTip(this, result.message)
      this.ruleDialog = false
    },
    // 导入事件
    importClick: async function ({ file }) {
      await base.importClick(file)
    },
    // 导出事件
    exportClick: async function () {
      await base.exportClick()
    },
    // 提交事件
    submitClick: async function () {
      await base.submitClick()
    },
    // 取消事件
    cancelDialog: async function () {
      await base.cancelDialog()
    },
    // 删除事件
    deleteClick: async function (item) {
      await base.deleteClick(item)
    },
    // 搜索事件
    searchClick: async function () {
      await base.getList()
    },
    // 重置事件
    resetClick: async function () {
      await base.resetClick()
    },
    // 添加事件
    addClick: function () {
      base.addClick()
    },
    // 分页事件
    pagingClick: async function (val) {
      await base.pagingClick(val)
    },
    // 编辑事件
    editClick: async function (item) {
      await base.editClick(item)
    },
    // 子组件修改父组件data
    changDataValue: function (field, value) {
      this.form[field] = value
    }
  }
}

<template>
  <div class="manage">

    <!--    搜索区域开始   -->
    <el-collapse>
      <el-collapse-item title="搜索" name="1">
        <el-form :model="searchForm"
                 :inline="true"
                 label-width="80px">
        <el-form-item label="角色名称" prop="name">
          <el-input placeholder="请输入角色名称" v-model="searchForm.name"/>
        </el-form-item>
        <el-form-item label="帐号状态" prop="status">
          <el-select v-model="searchForm.status" placeholder="请选择帐号状态">
            <el-option
              v-for="(item, index) in pageData.select.status"
              :key="index"
              :label="item"
              :value="index*1">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间" prop="create_time">
          <el-date-picker
            v-model="searchForm.create_time_array"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '00:00:00']">
          </el-date-picker>
        </el-form-item>
        </el-form>
      </el-collapse-item>
    </el-collapse>
    <!--    搜索区域结束   -->

    <!--    分割线-->
    <el-divider></el-divider>

    <!-- 弹窗区域开始 -->
    <el-dialog
      :title="this.scene === 1 ? '新增' : '编辑'"
      :visible.sync="showDialog"
      width="50%"
      :before-close="cancelDialog">
      <!-- 用户的表单信息 -->
      <el-form
        ref="form"
        :inline="false"
        :model="form"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item label="角色名称" prop="name">
          <el-input placeholder="请输入角色名称" v-model="form.name"/>
        </el-form-item>
        <el-form-item label="帐号状态" prop="status">
          <el-select v-model="form.status" placeholder="请选择帐号状态">
            <el-option v-for="(item, index) in pageData.select.status"
                       :key="index"
                       :label="item"
                       :value="index*1" />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button @click="cancelDialog">取 消</el-button>
          <el-button type="primary" @click="submitClick">确 定</el-button>
        </span>
    </el-dialog>
    <!-- 弹窗区域结束 -->

    <!--    分配权限-->
    <el-dialog
      title="分配权限"
      :visible.sync="ruleDialog"
      width="50%"
      :before-close="ruleClose">
      <el-tree
        :data="menuTree"
        ref="subRoleTree"
        show-checkbox
        node-key="id"
        :default-expand-all="false"
        :default-checked-keys="MenuChecked"
        :props="{
                    children: 'children',
                    label: 'name'
                  }">
      </el-tree>
      <!-- 用户的表单信息 -->
      <span slot="footer" class="dialog-footer">
          <el-button @click="ruleClose">取 消</el-button>
          <el-button type="primary" @click="ruleSubmitButton">确 定</el-button>
        </span>
    </el-dialog>

    <!-- 表头按钮区域开始 -->
    <div class="manage-header">
      <el-button size="mini" class="el-icon-plus" type="primary" @click="addClick" plain>新增</el-button>
      <el-button size="mini" class="el-icon-search" type="success" @click="searchClick" plain>搜索</el-button>
      <el-button size="mini" class="el-icon-refresh-right" type="warning" @click="resetClick" plain>重置</el-button>
      <el-button size="mini" class="el-icon-download" type="info" @click="exportClick" plain>导出</el-button>
      <el-upload action="#"
                 :show-file-list="false"
                 :http-request="importClick">
        <el-button size="mini" class="el-icon-upload" type="info" plain>导入</el-button>
      </el-upload>
    </div>
    <!-- 表头按钮区域结束 -->

    <!-- 表格 -->
    <el-table
       :data="pageData.list"
       style="width: 100%;margin-bottom: 20px;"
       row-key="id"
       border
       v-loading="tableLoad">
      <el-table-column prop="id" label="id"/>
      <el-table-column prop="name" label="角色名称"/>
      <el-table-column prop="status_text" label="帐号状态"/>
      <el-table-column prop="create_time" label="创建时间"/>
      <el-table-column prop="update_time" label="更新时间"/>
      <el-table-column label="操作">
        <template  v-slot="{row}">
          <el-button size="mini" @click="editClick(row)">编辑</el-button>
          <el-button size="mini" @click="deleteClick(row)" type="danger">删除</el-button>
          <el-button v-if="row.id !== 1" size="mini" type="info" @click="ruleClick(row)" plain>权限</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <div>
      <el-pagination
        layout="prev, pager, next"
        :total="pageData.total"
        @current-change="pagingClick" />
    </div>
  </div>
</template>

<script>
import '@/assets/static/css/base.css'
import service from '@/service/System/RoleService'
export default service
</script>
